<template>
    <div class="manage-shift">
        <div class="title">
            <i class="material-icons">point_of_sale</i>
            <p>ניהול משמרת</p>
        </div>
        <p class="sub-title">עובדים במשמרת</p>
        <div class="wokers-on-shift">
            <template v-for="worker in workers_on_shift" :key="worker.id">
                <div class="worker-box">
                    <div class="finish-him" @click="clock_out_shift(worker)">
                        <i class="material-icons">timer_off</i>
                    </div>
                    <div class="worker-name">
                        <p>{{worker.name}}</p>
                    </div>
                    <div class="worker-image">
                        <img :src="worker.image" alt="">
                    </div>
                </div>
            </template>
        </div>
        <p class="sub-title">עובדים בשירות</p>
        <div class="wokers-on-service">
            <template v-for="worker in workers_on_service" :key="worker.id">
                <div class="worker-box">
                    <div class="finish-him" @click="clock_out_service(worker)">
                        <i class="material-icons">timer_off</i>
                    </div>
                    <div class="worker-name">
                        <p>{{worker.name}}</p>
                    </div>
                    <div class="worker-image">
                        <img :src="worker.image" alt="">
                    </div>
                </div>
            </template>
        </div>
        <div class="shift-tools">
            <el-button type="primary" @click="finish_shift">סיים משמרת</el-button>
        </div>
    </div>
</template>

<script>
import { computed, ref } from '@vue/reactivity'
import store from '../../store'
import { clock_out_service } from '../../Methods/Service'
import { clock_out_shift } from '../../Methods/Workers'
import { onBeforeMount } from '@vue/runtime-core'
import router from '../../router'
import { finish_shift } from '../../Methods/Shift'
export default {
setup(){
    
    const workers_on_shift = ref(computed(() => {
        if(store.state.shift){
            return store.state.shift.workers.filter(worker => {
                return worker.shift.active == true
            })
        }
    }))

    const workers_on_service = ref(computed(() => {
        if(store.state.shift){
            return store.state.shift.workers.filter(worker => {
                return worker.service.active == true
            })
        }
    }))

    onBeforeMount(() => {
        if(!store.state.shift){
            router.push('/work')
        }
    })
    return{
        workers_on_shift, workers_on_service,clock_out_service,clock_out_shift, finish_shift
    }
}
}
</script>

<style scoped>
.manage-shift{
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin: auto;
    height: 100%;
}
.title{
    width: 100%;
    height: 80px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: var(--yellow);
    background: var(--secondary);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.279);
}
.wokers-on-shift{
    width: 100%;
    height: calc((100% - 80px - 60px - 60px) / 2 );
    overflow-y: auto;
    display: grid;
    grid-template-columns:repeat(auto-fill,minmax(150px, 1fr));
    grid-auto-rows: 150px;
    grid-gap: 5px;
    padding: 5px 0 5px 0;
    flex-shrink: 0;
}
.wokers-on-service{
    width: 100%;
    height: calc((100% - 80px - 60px - 60px) / 2 );
    overflow-y: auto;
    display: grid;
    grid-template-columns:repeat(auto-fill,minmax(150px, 1fr));
    grid-auto-rows: 150px;
    grid-gap: 5px;
    padding: 5px 0 5px 0;
    flex-shrink: 0;
}
.worker-box{
    position: relative;
    width: 100%;
    height: 100%;
    background: var(--secondary);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.worker-image{
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border-radius: 10px;
}
.worker-image img{
    max-width: 160px;
    opacity: 0.45;
    border-radius: 10px;
    overflow: hidden;
    transition: 0.5s;
}
.worker-image:hover img{
    opacity: 0.8;
}
.worker-name{
    position: absolute;
    bottom: 0;
    z-index: 2;
    width: 100%;
    background: rgba(4, 38, 82, 0.459);
    height: 30px;
    color: var(--light);
    text-shadow: 0 0 3px black;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    white-space: nowrap;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}
.sub-title{
    width: 100%;
    text-align: start;
    color: var(--yellow);
    padding: 5px;
}
.finish-him{
    position: absolute;
    top: 5px;
    left: 5px;
    width: 40px;
    height: 40px;
    background: var(--secondary);
    z-index: 2;
    border-radius: 50%;
    transition: 0.25s;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 0 5px #ea545479;
}
.finish-him:hover{
    box-shadow: 0 0 10px var(--success);
}
.finish-him:hover i{
    color: var(--success);
}
.finish-him i{
    transition: 0.25s;
    color: var(--danger);
}
.shift-tools{
    width: 100%;
    height: 60px;
    flex-shrink: 0;
    background: var(--alert-purple);
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 5px 0 5px;
    overflow-x: auto;
}
@media only screen and (max-width: 600px) {
    .wokers-on-shift{
        padding: 5px;
        grid-template-columns:repeat(auto-fill,minmax(110px, 1fr));
        grid-auto-rows: 110px;
    }
    .wokers-on-service{
        padding: 5px;
        grid-template-columns:repeat(auto-fill,minmax(110px, 1fr));
        grid-auto-rows: 110px;
    }
}
</style>